import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AudienceHistoricalSurveys,
  AudienceResponseDistribution,
  AudienceSurveysMetadata,
  AudienceTotalSurveys,
  CertificationsEarned,
  CodeContributors,
  CourseRegistrations,
  CourseSpendPerTrainingOrg,
  EngagementScores,
  EngagementScoresByMonth,
  EventAggregateMetrics,
  EventAggregateOrgStats,
  EventOverviewResponse,
  EventPrediction,
  EventPredictionDrilldown,
  EventRegistrationByYear,
  EventRegistrationCountry,
  EventRegistrationDrilldown,
  EventRegistrations,
  EventSponsorshipResponse,
  HealthMetricMember,
  HealthMetricNonMember,
  MailingListStats,
  MarketingOverview,
  MeetingBoardAttendeeDrilldown,
  MeetingBoardParticipation,
  MeetingOrganization,
  MemberContributions,
  MemberEngagementKeyMetric,
  MemberEventAttendanceList,
  MemberEventAttendanceOverTime,
  MemberGrowth,
  MemberMeetingAttendance,
  MemberMeetingAttendanceChart,
  MemberMostActiveIndividual,
  MemberMostAttendedEvent,
  MembersChurnRisk,
  MembersCorporateTrainingMostPopularCourse,
  MembersCorporateTrainingPurchased,
  MembersCorporateTrainingPurchaseList,
  MembersEventSponsorshipInvestedEvent,
  MembersEventSponsorshipPeople,
  MembershipAccount,
  MembershipBreakdown,
  MembershipChurn,
  MembershipChurnRate,
  MembershipLossReason,
  MembershipOutstandingBalance,
  MembershipOutstandingBalanceBreakdown,
  MemberSpeakerAcceptanceRate,
  MemberSpeakerImportantEvent,
  MemberSpeakerProposal,
  MemberSpeakerProposalChart,
  MemberSurveysRespondent,
  MostActiveLearners,
  MostPopularCourses,
  NetPromoterScore,
  OrganizationSpeaker,
  ProjectGoal,
  SpeakerProposalBreakdown,
  SpeakerProposalsPerYear,
  SpeakersMostActiveOrganization,
  SurveyResponsesAudience,
  Training,
  TrainingByCountry,
  TrainingByCourse,
  TrainingEnrollmentByYear,
  TrainingsEnrolled
} from 'lfx-pcc';
import { DateTime } from 'luxon';
import { BehaviorSubject, map, Observable, shareReplay, tap } from 'rxjs';

import { WebSocketService } from './web-socket.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public range$: BehaviorSubject<string> = new BehaviorSubject<string>('YTD');
  public goals$: BehaviorSubject<ProjectGoal[]> = new BehaviorSubject<ProjectGoal[]>([]);
  public goals: ProjectGoal[] = [];

  private service: string = 'report-services';
  private urls = {
    clearCache: `${this.service}/clear-cache`,
    projectGoals: `${this.service}/project-goals`,
    eventsOverview: `${this.service}/events-overview`,
    eventsRegistrations: `${this.service}/events-registrations`,
    eventsRegistrationsEventDrilldown: `${this.service}/events-registrations-event-drilldown`,
    eventsSponsorship: `${this.service}/events-sponsorship`,
    eventsRegistrationCountry: `${this.service}/events-registration-country`,
    eventsRegistrationByYear: `${this.service}/events-registration-by-year`,
    eventsUpcoming: `${this.service}/events-upcoming`,
    eventPredictions: `${this.service}/events-registration-predictions`,
    eventPredictionsDrilldown: `${this.service}/events-registration-predictions-drilldown`,
    meetingsBoardParticipation: `${this.service}/meetings-board-participation`,
    meetingsBoardAttendeeDrilldown: `${this.service}/meetings-board-attendee-drilldown`,
    meetingsOrganizations: `${this.service}/meetings-organizations`,
    codeContributors: `${this.service}/code-contributors`,
    marketingOverview: `${this.service}/marketing-overview`,
    membershipAccounts: `${this.service}/membership-accounts`,
    nonMembershipAccounts: `${this.service}/non-membership-accounts`,
    training: `${this.service}/training`,
    trainingByYear: `${this.service}/training-by-year`,
    trainingByCourse: `${this.service}/training-by-course`,
    trainingByCountry: `${this.service}/training-enrollment-by-country`,
    memberGrowth: `${this.service}/member-growth`,
    memberGrowthBreakdown: `${this.service}/member-growth-breakdown`,
    membersList: `${this.service}/members-list`,
    nonMembersList: `${this.service}/non-members-list`,
    memberContributions: `${this.service}/member-contributions`,
    nonMemberContributions: `${this.service}/non-member-contributions`,
    memberEngagementScores: `${this.service}/member-engagement-scores`,
    memberEngagementScoresByMonth: `${this.service}/member-engagement-scores-by-month`,
    memberEngagementKeyMetrics: `${this.service}/member-engagement-key-metrics`,
    netPromoterScore: `${this.service}/net-promoter-score`,
    surveyResponseAudience: `${this.service}/survey-response-audience`,
    audienceDrillDown: `${this.service}/audience-drilldown`,
    audienceResponseDistribution: `${this.service}/audience-response-distribution`,
    audienceHistoricalSurveys: `${this.service}/audience-historical-surveys`,
    audienceTotalSurveys: `${this.service}/audience-total-surveys`,
    audienceSurveysMetadata: `${this.service}/audience-surveys-metadata`,
    memberSatisfactionNPS: `${this.service}/member-satisfaction-nps`,
    memberSatisfactionResponseDistribution: `${this.service}/member-satisfaction-response-distribution`,
    memberSatisfactionHistoricalSurveys: `${this.service}/member-satisfaction-historical-surveys`,
    memberSatisfactionTotalSurveys: `${this.service}/member-satisfaction-total-surveys`,
    memberSatisfactionRespondents: `${this.service}/member-satisfaction-respondents`,
    membershipChurnRate: `${this.service}/membership-churn-rate`,
    membershipChurnRateLastYear: `${this.service}/membership-churn-rate-last-year`,
    membershipChurnRateByTier: `${this.service}/membership-churn-rate-by-tier`,
    membershipChurnRateByTierYear: `${this.service}/membership-churn-rate-by-tier-year`,
    memberChurnList: `${this.service}/member-churn-list`,
    membersOutstandingBalance: `${this.service}/members-outstanding-balance`,
    membersOutstandingBalanceBreakdown: `${this.service}/members-outstanding-balance-breakdown`,
    membersChurnRisk: `${this.service}/members-churn-risk`,
    membershipChurnRateByYear: `${this.service}/membership-churn-rate-by-year`,
    memberLossReason: `${this.service}/member-loss-reason`,
    eventAggregateMetrics: `${this.service}/event-aggregate-metrics`,
    eventAggregateOrgStats: `${this.service}/event-aggregate-org-stats`,
    membersCorporateTrainingPurchased: `${this.service}/members-corporate-training-purchased`,
    membersCorporateTrainingMostPopularCourse: `${this.service}/members-corporate-training-most-popular-courses`,
    membersCorporateTrainingPurchaseList: `${this.service}/members-corporate-training-purchases-list`,
    membersEventSponsorshipsPeople: `${this.service}/members-event-sponsorships-people`,
    membersEventSponsorshipsInvestedEvents: `${this.service}/members-event-sponsorships-invested-events`,
    membersEventSponsorshipsOverTime: `${this.service}/members-event-sponsorships-over-time`,
    membersMeetingAttendance: `${this.service}/members-meeting-attendance`,
    membersPercentMeetingAttended: `${this.service}/members-percent-meeting-attended`,
    membersMeetingAttendanceList: `${this.service}/members-meeting-attendance-list`,
    memberSpeakerProposalChart: `${this.service}/members-speaker-proposal-chart`,
    memberSpeakerAcceptedRateOverTime: `${this.service}/members-speaker-accepted-rate`,
    memberSpeakerMostImportantEvents: `${this.service}/members-speaker-important-events`,
    memberSpeakerProposal: `${this.service}/members-speaker-proposal`,
    speakerProposalsBreakdown: `${this.service}/speaker-proposals-breakdown`,
    speakerProposalsPerYear: `${this.service}/speaker-proposals-per-year`,
    speakerProposalsMostActiveOrgs: `${this.service}/speaker-proposals-most-active-orgs`,
    organizationSpeakersList: `${this.service}/organization-speakers-list`,
    mailingListStats: `${this.service}/mailing-list-stats`,
    mailingListChart: `${this.service}/mailing-list-chart`,
    mostEventAttended: `${this.service}/most-event-attended`,
    mostActiveIndividuals: `${this.service}/most-active-individuals`,
    eventAttendanceOverTime: `${this.service}/event-attendance-over-time`,
    eventAttendanceList: `${this.service}/event-attendance-list`,
    memberCourseSpendPerTrainingOrg: `${this.service}/member-course-spend-per-training-org`,
    memberCertificationsEarned: `${this.service}/member-certifications-earned`,
    memberTrainingsEnrolled: `${this.service}/member-trainings-enrolled`,
    memberMostPopularCourses: `${this.service}/member-most-popular-courses`,
    memberMostActiveLearners: `${this.service}/member-most-active-learners`,
    memberCourseRegistrations: `${this.service}/member-course-registrations`
  };

  private socket: WebSocketService = inject(WebSocketService);

  public constructor() {}

  public clearCache(id: string): Observable<any> {
    this.socket.connect(this.urls.clearCache, id);
    this.socket.emit(this.urls.clearCache, 'message', { id });
    return this.socket.onMessage(this.urls.clearCache, 'message');
  }

  public getGoal$(payload: { project_id: string; goal_name: string; goal_type: string; time_range_start_year?: number }): Observable<ProjectGoal | null> {
    return this.goals$.pipe(
      shareReplay(1),
      map((goals) => {
        const goal = payload.time_range_start_year
          ? goals.find(
              (goal) =>
                goal.goal_name === payload.goal_name &&
                goal.goal_type === payload.goal_type &&
                DateTime.fromISO(goal.time_range_start).year === payload.time_range_start_year
            )
          : goals.find((goal) => goal.goal_name === payload.goal_name && goal.goal_type === payload.goal_type);
        if (goal) {
          return goal;
        }

        return null;
      })
    );
  }

  public getProjectEventsSponsorshipGoal$(): Observable<number> {
    return this.goals$.pipe(
      map((goals) => {
        return goals.find((goal) => goal.goal_name === 'Sponsorship Goal' && goal.goal_type === 'Events')?.goal_value || 0;
      })
    );
  }

  public getProjectGoals(payload: { project_id: string }): Observable<ProjectGoal[]> {
    this.socket.connect(this.urls.projectGoals, payload.project_id);
    this.socket.emit(this.urls.projectGoals, 'message', payload);
    return this.socket.onMessage(this.urls.projectGoals, 'message').pipe(
      shareReplay(1),
      untilDestroyed(this),
      tap((response) => {
        this.goals = response;
        this.goals$.next(response);
      })
    );
  }

  public setProjectGoals(payload: { project?: string; project_id: string; goals: ProjectGoal[] }): Observable<any> {
    this.socket.connect(this.urls.projectGoals, payload.project_id);
    this.socket.emit(this.urls.projectGoals, 'update', payload);
    return this.socket.onMessage(this.urls.projectGoals, 'update');
  }

  public getEventsOverview(payload: { project: string; project_id: string; range: string }): Observable<EventOverviewResponse> {
    this.socket.connect(this.urls.eventsOverview, payload.project_id);
    this.socket.emit(this.urls.eventsOverview, 'message', { report: 'events-overviews', ...payload });
    return this.socket.onMessage(this.urls.eventsOverview, 'message');
  }

  public getEventsSponsorship(payload: { project: string; project_id: string; range: string }): Observable<EventSponsorshipResponse> {
    this.socket.connect(this.urls.eventsSponsorship, payload.project_id);
    this.socket.emit(this.urls.eventsSponsorship, 'message', { report: 'events-sponsorship', ...payload });
    return this.socket.onMessage(this.urls.eventsSponsorship, 'message');
  }
  public getEventRegistrationCountry(payload: { project: string; project_id: string; range: string }): Observable<EventRegistrationCountry[]> {
    this.socket.connect(this.urls.eventsRegistrationCountry, payload.project_id);
    this.socket.emit(this.urls.eventsRegistrationCountry, 'message', { report: 'events-registration-country-r1', ...payload });
    return this.socket.onMessage(this.urls.eventsRegistrationCountry, 'message');
  }

  public getEventRegistrationByYear(payload: { project_id: string }): Observable<EventRegistrationByYear[]> {
    const url = this.urls.eventsRegistrationByYear;
    this.socket.connect(url, payload.project_id);
    this.socket.emit(url, 'message', { report: 'events-registration-by-year', ...payload });
    return this.socket.onMessage(url, 'message');
  }

  public getEventsUpcoming(payload: any): Observable<any> {
    this.socket.connect(this.urls.eventsUpcoming, payload.project_id);
    this.socket.emit(this.urls.eventsUpcoming, 'message', { report: 'events-upcoming', ...payload });
    return this.socket.onMessage(this.urls.eventsUpcoming, 'message');
  }
  public getEventsRegistrations(payload: {
    project_id: string;
    searchText?: string;
    current_and_future_years?: boolean;
    last_completed_year?: boolean;
    order_by?: string;
    order_dir?: string;
  }): Observable<EventRegistrations[]> {
    const url = this.urls.eventsRegistrations;
    this.socket.connect(url, payload.project_id);
    this.socket.emit(url, 'message', { report: 'events-registrations', ...payload });
    return this.socket.onMessage(url, 'message');
  }
  public getEventsRegistrationsEventDrilldown(payload: {
    eventId: string;
    eventName: string;
    timeDimension?: string;
    project_id: string;
  }): Observable<EventRegistrationDrilldown> {
    const url = this.urls.eventsRegistrationsEventDrilldown;
    this.socket.connect(url, payload.project_id);
    this.socket.emit(url, 'message', { report: 'events-registrations-event-drilldown-r1', ...payload });
    return this.socket.onMessage(url, 'message');
  }

  public getEventRegistrationPredictions(payload: any): Observable<EventPrediction[]> {
    this.socket.connect(this.urls.eventPredictions, payload.project_id);
    this.socket.emit(this.urls.eventPredictions, 'message', { report: 'event-registration-predictions', ...payload });
    return this.socket.onMessage(this.urls.eventPredictions, 'message');
  }

  public getEventRegistrationPredictionsDrilldown(payload: any): Observable<EventPredictionDrilldown[]> {
    this.socket.connect(this.urls.eventPredictionsDrilldown, payload.project_id);
    this.socket.emit(this.urls.eventPredictionsDrilldown, 'message', { report: 'event-registration-predictions-drilldown', ...payload });
    return this.socket.onMessage(this.urls.eventPredictionsDrilldown, 'message');
  }

  public getMeetingsBoardParticipation(payload: any): Observable<MeetingBoardParticipation[]> {
    this.socket.connect(this.urls.meetingsBoardParticipation, payload.project_id);
    this.socket.emit(this.urls.meetingsBoardParticipation, 'message', { report: 'meetings-board-participation', ...payload });
    return this.socket.onMessage(this.urls.meetingsBoardParticipation, 'message');
  }

  public getMeetingsBoardMeetingAttendeeDrilldown(payload: any): Observable<MeetingBoardAttendeeDrilldown[]> {
    this.socket.connect(this.urls.meetingsBoardAttendeeDrilldown, payload.project_id);
    this.socket.emit(this.urls.meetingsBoardAttendeeDrilldown, 'message', { report: 'meetings-board-attendee-drilldown', ...payload });
    return this.socket.onMessage(this.urls.meetingsBoardAttendeeDrilldown, 'message');
  }

  public getMeetingsOrganizations(payload: any): Observable<MeetingOrganization[]> {
    this.socket.connect(this.urls.meetingsOrganizations, payload.project_id);
    this.socket.emit(this.urls.meetingsOrganizations, 'message', { report: 'meetings-organizations', ...payload });
    return this.socket.onMessage(this.urls.meetingsOrganizations, 'message');
  }

  public getCodeContributors(payload: any): Observable<CodeContributors[]> {
    this.socket.connect(this.urls.codeContributors, payload.project_id);
    this.socket.emit(this.urls.codeContributors, 'message', { report: 'code-contributors', ...payload });
    return this.socket.onMessage(this.urls.codeContributors, 'message');
  }

  public getMarketingOverview(payload: any): Observable<MarketingOverview[]> {
    this.socket.connect(this.urls.marketingOverview, payload.project_id);
    this.socket.emit(this.urls.marketingOverview, 'message', { report: 'marketing-overview', ...payload });
    return this.socket.onMessage(this.urls.marketingOverview, 'message');
  }

  public getMembershipAccounts(payload: any): Observable<MembershipAccount[]> {
    this.socket.connect(this.urls.membershipAccounts, payload.project_id);
    this.socket.emit(this.urls.membershipAccounts, 'message', { report: 'membership-accounts', ...payload });
    return this.socket.onMessage(this.urls.membershipAccounts, 'message');
  }

  public getNonMembershipAccounts(payload: any): Observable<MembershipAccount[]> {
    this.socket.connect(this.urls.nonMembershipAccounts, payload.project_id);
    this.socket.emit(this.urls.nonMembershipAccounts, 'message', { report: 'non-membership-accounts', ...payload });
    return this.socket.onMessage(this.urls.nonMembershipAccounts, 'message');
  }

  public getTraining(payload: { project_id: string; project?: string; range: string }): Observable<{ training: Training[]; project_id: string }> {
    this.socket.connect(this.urls.training, payload.project_id);
    this.socket.emit(this.urls.training, 'message', { report: 'training', ...payload });
    return this.socket.onMessage(this.urls.training, 'message');
  }

  public getTrainingByYear(payload: any): Observable<TrainingEnrollmentByYear[]> {
    this.socket.connect(this.urls.trainingByYear, payload.project_id);
    this.socket.emit(this.urls.trainingByYear, 'message', { report: 'training-by-year', ...payload });
    return this.socket.onMessage(this.urls.trainingByYear, 'message');
  }

  public getTrainingByCourse(payload: { project_id: string; range: string }): Observable<TrainingByCourse[]> {
    const url = this.urls.trainingByCourse;
    this.socket.connect(url, payload.project_id);
    this.socket.emit(url, 'message', { report: 'training-by-course-r1', ...payload });
    return this.socket.onMessage(url, 'message');
  }

  public getTrainingByCountry(payload: { project_id: string; range: string }): Observable<TrainingByCountry[]> {
    const url = this.urls.trainingByCountry;
    this.socket.connect(url, payload.project_id);
    this.socket.emit(url, 'message', { report: 'training-enrollment-by-country-r2', ...payload });
    return this.socket.onMessage(url, 'message');
  }

  public getMemberGrowth(payload: any): Observable<MemberGrowth[]> {
    // Query is located in the membership-queries.service.ts#L157
    this.socket.connect(this.urls.memberGrowth, payload.project_id);
    this.socket.emit(this.urls.memberGrowth, 'message', { report: 'member-growth', ...payload });
    return this.socket.onMessage(this.urls.memberGrowth, 'message');
  }

  public getMembershipBreakdown(payload: any): Observable<MembershipBreakdown[]> {
    // Query is located in the membership-queries.service.ts#L173
    this.socket.connect(this.urls.memberGrowthBreakdown, payload.project_id);
    this.socket.emit(this.urls.memberGrowthBreakdown, 'message', { report: 'member-growth-breakdown', ...payload });
    return this.socket.onMessage(this.urls.memberGrowthBreakdown, 'message');
  }

  public getMembersList(payload: any): Observable<HealthMetricMember[]> {
    this.socket.connect(this.urls.membersList, payload.project_id);
    this.socket.emit(this.urls.membersList, 'message', { report: 'members-list', ...payload });
    return this.socket.onMessage(this.urls.membersList, 'message');
  }

  public getNonMembersList(payload: any): Observable<HealthMetricNonMember[]> {
    this.socket.connect(this.urls.nonMembersList, payload.project_id);
    this.socket.emit(this.urls.nonMembersList, 'message', { report: 'non-members-list', ...payload });
    return this.socket.onMessage(this.urls.nonMembersList, 'message');
  }

  public getMemberContributions(payload: any): Observable<MemberContributions[]> {
    this.socket.connect(this.urls.memberContributions, payload.project_id);
    this.socket.emit(this.urls.memberContributions, 'message', { report: 'member-contributions', ...payload });
    return this.socket.onMessage(this.urls.memberContributions, 'message');
  }

  public getNonMemberContributions(payload: any): Observable<MemberContributions[]> {
    this.socket.connect(this.urls.nonMemberContributions, payload.project_id);
    this.socket.emit(this.urls.nonMemberContributions, 'message', { report: 'non-member-contributions', ...payload });
    return this.socket.onMessage(this.urls.nonMemberContributions, 'message');
  }

  public getMemberEngagementScores(payload: any): Observable<EngagementScores[]> {
    this.socket.connect(this.urls.memberEngagementScores, payload.project_id);
    this.socket.emit(this.urls.memberEngagementScores, 'message', { report: 'member-engagement-scores', ...payload });
    return this.socket.onMessage(this.urls.memberEngagementScores, 'message');
  }

  public getMemberEngagementScoresByMonth(payload: any): Observable<EngagementScoresByMonth[]> {
    this.socket.connect(this.urls.memberEngagementScoresByMonth, payload.project_id);
    this.socket.emit(this.urls.memberEngagementScoresByMonth, 'message', { report: 'member-engagement-scores-by-month', ...payload });
    return this.socket.onMessage(this.urls.memberEngagementScoresByMonth, 'message');
  }

  public getMemberEngagementKeyMetrics(payload: any): Observable<MemberEngagementKeyMetric[]> {
    this.socket.connect(this.urls.memberEngagementKeyMetrics, payload.project_id);
    this.socket.emit(this.urls.memberEngagementKeyMetrics, 'message', { report: 'member-engagement-key-metrics', ...payload });
    return this.socket.onMessage(this.urls.memberEngagementKeyMetrics, 'message');
  }

  public getMembershipChurnRate(payload: any): Observable<MembershipChurnRate[]> {
    this.socket.connect(this.urls.membershipChurnRate, payload.project_id);
    this.socket.emit(this.urls.membershipChurnRate, 'message', { report: 'membership-churn-rate-overview', ...payload });
    return this.socket.onMessage(this.urls.membershipChurnRate, 'message');
  }

  public getMembershipChurnRateLastYear(payload: any): Observable<MembershipChurnRate[]> {
    this.socket.connect(this.urls.membershipChurnRateLastYear, payload.project_id);
    this.socket.emit(this.urls.membershipChurnRateLastYear, 'message', { report: 'membership-churn-rate-overview-last-year', ...payload });
    return this.socket.onMessage(this.urls.membershipChurnRateLastYear, 'message');
  }

  public getMembershipChurnRateByTier(payload: any): Observable<MembershipChurnRate[]> {
    this.socket.connect(this.urls.membershipChurnRateByTier, payload.project_id);
    this.socket.emit(this.urls.membershipChurnRateByTier, 'message', { report: 'membership-churn-rate-by-tier', ...payload });
    return this.socket.onMessage(this.urls.membershipChurnRateByTier, 'message');
  }

  public getMembershipChurnRateByTierYear(payload: any): Observable<MembershipChurnRate[]> {
    this.socket.connect(this.urls.membershipChurnRateByTierYear, payload.project_id);
    this.socket.emit(this.urls.membershipChurnRateByTierYear, 'message', { report: 'membership-churn-rate-by-tier-year', ...payload });
    return this.socket.onMessage(this.urls.membershipChurnRateByTierYear, 'message');
  }

  public getMembershipChurnRateByYear(payload: any): Observable<MembershipChurnRate[]> {
    this.socket.connect(this.urls.membershipChurnRateByYear, payload.project_id);
    this.socket.emit(this.urls.membershipChurnRateByYear, 'message', { report: 'membership-churn-rate-by-tier-year', ...payload });
    return this.socket.onMessage(this.urls.membershipChurnRateByYear, 'message');
  }

  public getMemberChurnList(payload: any): Observable<MembershipChurn[]> {
    this.socket.connect(this.urls.memberChurnList, payload.project_id);
    this.socket.emit(this.urls.memberChurnList, 'message', { report: 'members-churn-list', ...payload });
    return this.socket.onMessage(this.urls.memberChurnList, 'message');
  }

  public getNetPromoterScore(payload: any): Observable<NetPromoterScore[]> {
    this.socket.connect(this.urls.netPromoterScore, payload.project_id);
    this.socket.emit(this.urls.netPromoterScore, 'message', { report: 'net-promoter-score', ...payload });
    return this.socket.onMessage(this.urls.netPromoterScore, 'message');
  }

  public getSurveyResponsesAudience(payload: any): Observable<SurveyResponsesAudience[]> {
    this.socket.connect(this.urls.surveyResponseAudience, payload.project_id);
    this.socket.emit(this.urls.surveyResponseAudience, 'message', { report: 'survey-response-audience', ...payload });
    return this.socket.onMessage(this.urls.surveyResponseAudience, 'message');
  }

  public getAudienceDrilldown(payload: any): Observable<NetPromoterScore[]> {
    this.socket.connect(this.urls.audienceDrillDown, payload.project_id);
    this.socket.emit(this.urls.audienceDrillDown, 'message', { report: 'audience-drilldown', ...payload });
    return this.socket.onMessage(this.urls.audienceDrillDown, 'message');
  }

  public getAudienceResponseDistribution(payload: any): Observable<AudienceResponseDistribution[]> {
    this.socket.connect(this.urls.audienceResponseDistribution, payload.project_id);
    this.socket.emit(this.urls.audienceResponseDistribution, 'message', { report: 'audience-response-distribution', ...payload });
    return this.socket.onMessage(this.urls.audienceResponseDistribution, 'message');
  }

  public getAudienceHistoricalSurveys(payload: any): Observable<AudienceHistoricalSurveys[]> {
    this.socket.connect(this.urls.audienceHistoricalSurveys, payload.project_id);
    this.socket.emit(this.urls.audienceHistoricalSurveys, 'message', { report: 'audience-historical-surveys', ...payload });
    return this.socket.onMessage(this.urls.audienceHistoricalSurveys, 'message');
  }

  public getAudienceTotalSurveys(payload: any): Observable<AudienceTotalSurveys[]> {
    this.socket.connect(this.urls.audienceTotalSurveys, payload.project_id);
    this.socket.emit(this.urls.audienceTotalSurveys, 'message', { report: 'audience-total-surveys', ...payload });
    return this.socket.onMessage(this.urls.audienceTotalSurveys, 'message');
  }

  public getAudienceSurveysMetadata(payload: any): Observable<AudienceSurveysMetadata[]> {
    this.socket.connect(this.urls.audienceSurveysMetadata, payload.project_id);
    this.socket.emit(this.urls.audienceSurveysMetadata, 'message', { report: 'audience-surveys-metadata', ...payload });
    return this.socket.onMessage(this.urls.audienceSurveysMetadata, 'message');
  }

  public getMemberSatisfactionNPS(payload: any): Observable<NetPromoterScore[]> {
    this.socket.connect(this.urls.memberSatisfactionNPS, payload.project_id);
    this.socket.emit(this.urls.memberSatisfactionNPS, 'message', { report: 'member-satisfaction-nps', ...payload });
    return this.socket.onMessage(this.urls.memberSatisfactionNPS, 'message');
  }

  public getMemberSatisfactionResponseDistribution(payload: any): Observable<AudienceResponseDistribution[]> {
    this.socket.connect(this.urls.memberSatisfactionResponseDistribution, payload.project_id);
    this.socket.emit(this.urls.memberSatisfactionResponseDistribution, 'message', { report: 'member-satisfaction-response-distribution', ...payload });
    return this.socket.onMessage(this.urls.memberSatisfactionResponseDistribution, 'message');
  }

  public getMemberSatisfactionHistoricalSurveys(payload: any): Observable<AudienceHistoricalSurveys[]> {
    this.socket.connect(this.urls.memberSatisfactionHistoricalSurveys, payload.project_id);
    this.socket.emit(this.urls.memberSatisfactionHistoricalSurveys, 'message', { report: 'member-satisfaction-historical-surveys', ...payload });
    return this.socket.onMessage(this.urls.memberSatisfactionHistoricalSurveys, 'message');
  }

  public getMemberSatisfactionTotalSurveys(payload: any): Observable<AudienceTotalSurveys[]> {
    this.socket.connect(this.urls.memberSatisfactionTotalSurveys, payload.project_id);
    this.socket.emit(this.urls.memberSatisfactionTotalSurveys, 'message', { report: 'member-satisfaction-total-surveys', ...payload });
    return this.socket.onMessage(this.urls.memberSatisfactionTotalSurveys, 'message');
  }

  public getMemberSatisfactionRespondentsList(payload: any): Observable<MemberSurveysRespondent[]> {
    this.socket.connect(this.urls.memberSatisfactionRespondents, payload.project_id);
    this.socket.emit(this.urls.memberSatisfactionRespondents, 'message', { report: 'member-satisfaction-respondents', ...payload });
    return this.socket.onMessage(this.urls.memberSatisfactionRespondents, 'message');
  }

  public getEventAggregateMetrics(payload: any): Observable<EventAggregateMetrics[]> {
    this.socket.connect(this.urls.eventAggregateMetrics, payload.project_id);
    this.socket.emit(this.urls.eventAggregateMetrics, 'message', { report: 'event-aggregate-metrics', ...payload });
    return this.socket.onMessage(this.urls.eventAggregateMetrics, 'message');
  }

  public getEventAggregateOrgStats(payload: any): Observable<EventAggregateOrgStats[]> {
    this.socket.connect(this.urls.eventAggregateOrgStats, payload.project_id);
    this.socket.emit(this.urls.eventAggregateOrgStats, 'message', { report: 'event-aggregate-org-stats', ...payload });
    return this.socket.onMessage(this.urls.eventAggregateOrgStats, 'message');
  }

  public getMemberLossReason(payload: any): Observable<MembershipLossReason[]> {
    this.socket.connect(this.urls.memberLossReason, payload.project_id);
    this.socket.emit(this.urls.memberLossReason, 'message', { report: 'member-loss-reason', ...payload });
    return this.socket.onMessage(this.urls.memberLossReason, 'message');
  }

  public getMailingListMetricStats(payload: any): Observable<MailingListStats[]> {
    this.socket.connect(this.urls.mailingListStats, payload.project_id);
    this.socket.emit(this.urls.mailingListStats, 'message', { report: 'mailing-list-stats', ...payload });
    return this.socket.onMessage(this.urls.mailingListStats, 'message');
  }

  public getMailingListMetricChart(payload: any): Observable<any[]> {
    this.socket.connect(this.urls.mailingListChart, payload.project_id);
    this.socket.emit(this.urls.mailingListChart, 'message', { report: 'mailing-list-chart', ...payload });
    return this.socket.onMessage(this.urls.mailingListChart, 'message');
  }

  public getMembersOutstandingBalance(payload: any): Observable<MembershipOutstandingBalance[]> {
    this.socket.connect(this.urls.membersOutstandingBalance, payload.project_id);
    this.socket.emit(this.urls.membersOutstandingBalance, 'message', { report: 'members-outstanding-balance', ...payload });
    return this.socket.onMessage(this.urls.membersOutstandingBalance, 'message');
  }

  public getMembersOutstandingBalanceBreakdown(payload: any): Observable<MembershipOutstandingBalanceBreakdown[]> {
    this.socket.connect(this.urls.membersOutstandingBalanceBreakdown, payload.project_id);
    this.socket.emit(this.urls.membersOutstandingBalanceBreakdown, 'message', { report: 'members-outstanding-balance-breakdown', ...payload });
    return this.socket.onMessage(this.urls.membersOutstandingBalanceBreakdown, 'message');
  }

  public getMembersChurnRisk(payload: any): Observable<MembersChurnRisk[]> {
    this.socket.connect(this.urls.membersChurnRisk, payload.project_id);
    this.socket.emit(this.urls.membersChurnRisk, 'message', { report: 'members-churn-risk', ...payload });
    return this.socket.onMessage(this.urls.membersChurnRisk, 'message');
  }

  public getMembersCorporateTrainingPurchased(payload: any): Observable<MembersCorporateTrainingPurchased[]> {
    this.socket.connect(this.urls.membersCorporateTrainingPurchased, payload.project_id);
    this.socket.emit(this.urls.membersCorporateTrainingPurchased, 'message', { report: 'members-corporate-training-purchased', ...payload });
    return this.socket.onMessage(this.urls.membersCorporateTrainingPurchased, 'message');
  }

  public getMembersCorporateTrainingMostPopularCourse(payload: any): Observable<MembersCorporateTrainingMostPopularCourse[]> {
    this.socket.connect(this.urls.membersCorporateTrainingMostPopularCourse, payload.project_id);
    this.socket.emit(this.urls.membersCorporateTrainingMostPopularCourse, 'message', { report: 'members-corporate-training-most-popular-courses', ...payload });
    return this.socket.onMessage(this.urls.membersCorporateTrainingMostPopularCourse, 'message');
  }

  public getMembersCorporateTrainingPurchase(payload: any): Observable<MembersCorporateTrainingPurchaseList[]> {
    this.socket.connect(this.urls.membersCorporateTrainingPurchaseList, payload.project_id);
    this.socket.emit(this.urls.membersCorporateTrainingPurchaseList, 'message', { report: 'members-corporate-training-purchases-list', ...payload });
    return this.socket.onMessage(this.urls.membersCorporateTrainingPurchaseList, 'message');
  }

  public getMembersEventSponsorshipsPeople(payload: any): Observable<MembersEventSponsorshipPeople[]> {
    this.socket.connect(this.urls.membersEventSponsorshipsPeople, payload.project_id);
    this.socket.emit(this.urls.membersEventSponsorshipsPeople, 'message', { report: 'members-event-sponsorships-people', ...payload });
    return this.socket.onMessage(this.urls.membersEventSponsorshipsPeople, 'message');
  }

  public getMembersEventSponsorshipsInvestedEvents(payload: any): Observable<MembersEventSponsorshipInvestedEvent[]> {
    this.socket.connect(this.urls.membersEventSponsorshipsInvestedEvents, payload.project_id);
    this.socket.emit(this.urls.membersEventSponsorshipsInvestedEvents, 'message', { report: 'members-event-sponsorships-invested-events', ...payload });
    return this.socket.onMessage(this.urls.membersEventSponsorshipsInvestedEvents, 'message');
  }

  public getMembersEventSponsorshipsOverTime(payload: any): Observable<any[]> {
    this.socket.connect(this.urls.membersEventSponsorshipsOverTime, payload.project_id);
    this.socket.emit(this.urls.membersEventSponsorshipsOverTime, 'message', { report: 'members-event-sponsorships-over-time', ...payload });
    return this.socket.onMessage(this.urls.membersEventSponsorshipsOverTime, 'message');
  }

  public getMemberSpeakerProposalChart(payload: any): Observable<MemberSpeakerProposalChart[]> {
    this.socket.connect(this.urls.memberSpeakerProposalChart, payload.project_id);
    this.socket.emit(this.urls.memberSpeakerProposalChart, 'message', { report: 'members-speaker-proposal-chart', ...payload });
    return this.socket.onMessage(this.urls.memberSpeakerProposalChart, 'message');
  }

  public getMemberSpeakerAcceptedRateOverTime(payload: any): Observable<MemberSpeakerAcceptanceRate[]> {
    this.socket.connect(this.urls.memberSpeakerAcceptedRateOverTime, payload.project_id);
    this.socket.emit(this.urls.memberSpeakerAcceptedRateOverTime, 'message', { report: 'members-speaker-accepted-rate', ...payload });
    return this.socket.onMessage(this.urls.memberSpeakerAcceptedRateOverTime, 'message');
  }

  public getMemberSpeakerMostImportantEvents(payload: any): Observable<MemberSpeakerImportantEvent[]> {
    this.socket.connect(this.urls.memberSpeakerMostImportantEvents, payload.project_id);
    this.socket.emit(this.urls.memberSpeakerMostImportantEvents, 'message', { report: 'members-speaker-important-events', ...payload });
    return this.socket.onMessage(this.urls.memberSpeakerMostImportantEvents, 'message');
  }

  public getMemberSpeakerProposal(payload: any): Observable<MemberSpeakerProposal[]> {
    this.socket.connect(this.urls.memberSpeakerProposal, payload.project_id);
    this.socket.emit(this.urls.memberSpeakerProposal, 'message', { report: 'members-speaker-proposal', ...payload });
    return this.socket.onMessage(this.urls.memberSpeakerProposal, 'message');
  }

  public getMembersMeetingAttendance(payload: any): Observable<MemberMeetingAttendanceChart[]> {
    this.socket.connect(this.urls.membersMeetingAttendance, payload.project_id);
    this.socket.emit(this.urls.membersMeetingAttendance, 'message', { report: 'members-meeting-attendance', ...payload });
    return this.socket.onMessage(this.urls.membersMeetingAttendance, 'message');
  }

  public getMembersPercentMeetingAttended(payload: any): Observable<MemberMeetingAttendanceChart[]> {
    this.socket.connect(this.urls.membersPercentMeetingAttended, payload.project_id);
    this.socket.emit(this.urls.membersPercentMeetingAttended, 'message', { report: 'members-percent-meeting-attended', ...payload });
    return this.socket.onMessage(this.urls.membersPercentMeetingAttended, 'message');
  }

  public getMembersMeetingAttendanceList(payload: any): Observable<MemberMeetingAttendance[]> {
    this.socket.connect(this.urls.membersMeetingAttendanceList, payload.project_id);
    this.socket.emit(this.urls.membersMeetingAttendanceList, 'message', { report: 'members-meeting-attendance-list', ...payload });
    return this.socket.onMessage(this.urls.membersMeetingAttendanceList, 'message');
  }
  public getSpeakerProposalsBreakdown(payload: any): Observable<SpeakerProposalBreakdown[]> {
    this.socket.connect(this.urls.speakerProposalsBreakdown, payload.project_id);
    this.socket.emit(this.urls.speakerProposalsBreakdown, 'message', { report: 'speaker-proposals-breakdown', ...payload });
    return this.socket.onMessage(this.urls.speakerProposalsBreakdown, 'message');
  }

  public getSpeakerProposalsPerYear(payload: any): Observable<SpeakerProposalsPerYear[]> {
    this.socket.connect(this.urls.speakerProposalsPerYear, payload.project_id);
    this.socket.emit(this.urls.speakerProposalsPerYear, 'message', { report: 'speaker-proposals-per-year', ...payload });
    return this.socket.onMessage(this.urls.speakerProposalsPerYear, 'message');
  }

  public getSpeakerProposalsMostActiveOrgs(payload: any): Observable<SpeakersMostActiveOrganization[]> {
    this.socket.connect(this.urls.speakerProposalsMostActiveOrgs, payload.project_id);
    this.socket.emit(this.urls.speakerProposalsMostActiveOrgs, 'message', { report: 'speaker-proposals-most-active-orgs', ...payload });
    return this.socket.onMessage(this.urls.speakerProposalsMostActiveOrgs, 'message');
  }

  public getOrganizationSpeakersList(payload: any): Observable<OrganizationSpeaker[]> {
    this.socket.connect(this.urls.organizationSpeakersList, payload.project_id);
    this.socket.emit(this.urls.organizationSpeakersList, 'message', { report: 'organization-speakers-list', ...payload });
    return this.socket.onMessage(this.urls.organizationSpeakersList, 'message');
  }

  public getMostAttendedEvent(payload: any): Observable<MemberMostAttendedEvent[]> {
    this.socket.connect(this.urls.mostEventAttended, payload.project_id);
    this.socket.emit(this.urls.mostEventAttended, 'message', { report: 'most-event-attended', ...payload });
    return this.socket.onMessage(this.urls.mostEventAttended, 'message');
  }

  public getMostActiveIndividuals(payload: any): Observable<MemberMostActiveIndividual[]> {
    this.socket.connect(this.urls.mostActiveIndividuals, payload.project_id);
    this.socket.emit(this.urls.mostActiveIndividuals, 'message', { report: 'most-active-individuals', ...payload });
    return this.socket.onMessage(this.urls.mostActiveIndividuals, 'message');
  }

  public getEventAttendanceOverTime(payload: any): Observable<MemberEventAttendanceOverTime[]> {
    this.socket.connect(this.urls.eventAttendanceOverTime, payload.project_id);
    this.socket.emit(this.urls.eventAttendanceOverTime, 'message', { report: 'event-attendance-over-time', ...payload });
    return this.socket.onMessage(this.urls.eventAttendanceOverTime, 'message');
  }

  public getEventAttendanceList(payload: any): Observable<MemberEventAttendanceList[]> {
    this.socket.connect(this.urls.eventAttendanceList, payload.project_id);
    this.socket.emit(this.urls.eventAttendanceList, 'message', { report: 'event-attendance-list', ...payload });
    return this.socket.onMessage(this.urls.eventAttendanceList, 'message');
  }

  public getMemberCourseSpendPerTrainingOrg(payload: any): Observable<CourseSpendPerTrainingOrg[]> {
    this.socket.connect(this.urls.memberCourseSpendPerTrainingOrg, payload.project_id);
    this.socket.emit(this.urls.memberCourseSpendPerTrainingOrg, 'message', { report: 'member-course-spend-per-training-org', ...payload });
    return this.socket.onMessage(this.urls.memberCourseSpendPerTrainingOrg, 'message');
  }

  public getMemberCertificationsEarned(payload: any): Observable<CertificationsEarned[]> {
    this.socket.connect(this.urls.memberCertificationsEarned, payload.project_id);
    this.socket.emit(this.urls.memberCertificationsEarned, 'message', { report: 'member-certifications-earned', ...payload });
    return this.socket.onMessage(this.urls.memberCertificationsEarned, 'message');
  }

  public getMemberTrainingsEnrolled(payload: any): Observable<TrainingsEnrolled[]> {
    this.socket.connect(this.urls.memberTrainingsEnrolled, payload.project_id);
    this.socket.emit(this.urls.memberTrainingsEnrolled, 'message', { report: 'member-trainings-enrolled', ...payload });
    return this.socket.onMessage(this.urls.memberTrainingsEnrolled, 'message');
  }

  public getMemberMostPopularCourses(payload: any): Observable<MostPopularCourses[]> {
    this.socket.connect(this.urls.memberMostPopularCourses, payload.project_id);
    this.socket.emit(this.urls.memberMostPopularCourses, 'message', { report: 'member-most-popular-courses', ...payload });
    return this.socket.onMessage(this.urls.memberMostPopularCourses, 'message');
  }

  public getMemberMostActiveLearners(payload: any): Observable<MostActiveLearners[]> {
    this.socket.connect(this.urls.memberMostActiveLearners, payload.project_id);
    this.socket.emit(this.urls.memberMostActiveLearners, 'message', { report: 'member-most-active-learners', ...payload });
    return this.socket.onMessage(this.urls.memberMostActiveLearners, 'message');
  }

  public getMemberCourseRegistrations(payload: any): Observable<CourseRegistrations[]> {
    this.socket.connect(this.urls.memberCourseRegistrations, payload.project_id);
    this.socket.emit(this.urls.memberCourseRegistrations, 'message', { report: 'member-course-registrations', ...payload });
    return this.socket.onMessage(this.urls.memberCourseRegistrations, 'message');
  }
}
