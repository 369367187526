import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import io, { Socket } from 'socket.io-client';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private sockets: { [url: string]: Socket } = {};
  private apiURL: string = environment.apiV2URL;

  private userService: UserService = inject(UserService);

  public constructor() {}

  public connect(url: string, id: string): void {
    // const transport = environment.environment === 'prod' ? ['websocket'] : ['polling', 'websocket'];
    if (!this.sockets[url]) {
      this.sockets[url] = io(`${this.apiURL}/${url}?project_id=${id}`, {
        path: environment.websocketPath,
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
        withCredentials: true,
        extraHeaders: {
          Authorization: `Bearer ${this.userService.accessToken}`
        }
      });
    }
  }

  public onMessage(url: string, event: string): Observable<any> {
    return new Observable((observer) => {
      if (!this.sockets[url]) {
        observer.error(new Error('No connection found for the provided URL.'));
        return;
      }

      this.sockets[url].on(event, (data) => {
        observer.next(data);
      });

      this.sockets[url].on('error', (error) => {
        observer.error(error);
      });
    });
  }

  public emit(url: string, event: string, data: any) {
    if (!this.sockets[url]) {
      throw new Error('No connection found for the provided URL.');
    }

    if (window.location.search.includes('refresh=true')) {
      data.refresh = true;
    }

    this.sockets[url].emit(event, data);
  }

  public disconnect(url: string) {
    if (this.sockets[url]) {
      this.sockets[url].disconnect();
      delete this.sockets[url];
    }
  }

  public disconnectAll() {
    for (const url in this.sockets) {
      this.sockets[url].disconnect();
      delete this.sockets[url];
    }
  }
}
