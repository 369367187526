import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ReportService } from '@app/shared/services/report.service';
import { DateTime } from 'luxon';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'lfx-health-metric-time-range',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './health-metric-time-range.component.html',
  styleUrls: ['./health-metric-time-range.component.scss']
})
export class HealthMetricTimeRangeComponent implements OnInit {
  public range$: Observable<string>;
  public from: string;
  public to: string;

  private reportService: ReportService = inject(ReportService);

  public ngOnInit(): void {
    this.range$ = this.reportService.range$.pipe(tap((range) => this.setFromDate(range)));
  }

  private setFromDate(value: string) {
    const now = DateTime.now();
    // Get the start of the last quarter
    const lastQuarterStart = now.minus({ quarter: 1 }).startOf('quarter');
    // Get the end of the last quarter
    const lastQuarterEnd = now.minus({ quarter: 1 }).endOf('quarter');

    switch (value) {
      case 'YTD':
        this.from = now.startOf('year').toLocaleString(DateTime.DATE_FULL);
        this.to = now.toLocaleString(DateTime.DATE_FULL);
        break;
      case '7D':
        // handle 7 days
        this.from = now.minus({ days: 7 }).toLocaleString(DateTime.DATE_FULL);
        this.to = now.toLocaleString(DateTime.DATE_FULL);
        break;
      case '30D':
        // handle 30 days
        this.from = now.minus({ days: 30 }).toLocaleString(DateTime.DATE_FULL);
        this.to = now.toLocaleString(DateTime.DATE_FULL);
        break;
      case '12M':
        // handle 12 months
        this.from = now.minus({ months: 12 }).startOf('month').toLocaleString(DateTime.DATE_FULL);
        this.to = now.minus({ month: 1 }).endOf('month').toLocaleString(DateTime.DATE_FULL);
        break;
      case 'QUARTER':
        // handle quarter
        this.from = lastQuarterStart.toLocaleString(DateTime.DATE_FULL);
        this.to = lastQuarterEnd.toLocaleString(DateTime.DATE_FULL);
        break;
      case 'ALL_TIME':
        // handle all time
        this.from = 'All Time';
        this.to = '';
        break;
      case 'COMPLETED_YEAR':
        // handle completed year
        this.from = now.minus({ year: 1 }).startOf('year').toLocaleString(DateTime.DATE_FULL);
        this.to = now.minus({ year: 1 }).endOf('year').toLocaleString(DateTime.DATE_FULL);
        break;
      default:
        // handle other cases
        break;
    }
  }
}
